import React from 'react';
import BlogTemplate from '../components/Template/BlogTemplate';
import { graphql } from 'gatsby';
import BlogCard from '../components/Cards/BlogCard';
import Seo from '../components/Partials/Seo';
import Pagination from '../components/Paginator/Pagination';
import dateFormat from 'dateformat';

export default function Blogs({ data }) {
  const { posts, pageInfo } = data.blog;
  const currentYear = new Date().getFullYear().toString();

  return (
    <BlogTemplate>
      <Seo
        title="Blog - Dicka Ismaji"
      />
      {
        posts.map((post, i) => {
          const year = dateFormat(post.frontmatter.date, 'yyyy');
          const addionalYear = year !== currentYear;

          return (<BlogCard key={i}
            className="py-4"
            title={post.frontmatter.title}
            slug={post.fields.slug}
            date={
              `${dateFormat(post.frontmatter.date, 'mmm d')} ${addionalYear ? `, ${year}` : ''}`
            }
            thumbnail={post.frontmatter.thumbnail}
            excerpt={post.excerpt}
            timeToRead={post.timeToRead}
          />);
        })
      }
      {pageInfo.pageCount > 1 &&
        <div className="flex justify-center">
          <Pagination
            pageCount={pageInfo.pageCount}
            page={pageInfo.currentPage}
            hasNextPage={pageInfo.hasNextPage}
            hasPreviousPage={pageInfo.hasPreviousPage}
          />
        </div>
      }
    </BlogTemplate>
  );
}

export const pageQuery = graphql`
  query BlogQuery($limit: Int!, $skip: Int!) {
    blog: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      totalCount
      posts: nodes {
        timeToRead
        excerpt
        fields {
          slug
        }
        frontmatter {
          author
          date
          title
          thumbnail
        }
      }
      pageInfo {
        perPage
        totalCount
        hasPreviousPage
        hasNextPage
        currentPage
        itemCount
        pageCount
      }
    }
  }
`;