import { Link } from 'gatsby';
import React, { useState } from 'react';

export default function Pagination({ page, pageCount, hasPreviousPage, hasNextPage }) {
  const [currentPage] = useState(page || 1);
  const firstPath = "/blog";

  const getPaginatonGroup = () => {
    let pages = [currentPage];

    [1, 2].forEach(i => {
      const minus = currentPage - i;
      if (minus > 0) {
        pages.unshift(minus);
      }

      const plus = currentPage + i;
      if (plus <= pageCount) {
        pages.push(plus);
      }
    });

    return pages;
  };

  return (
    <div className="my-4">
      <ul className="flex">
        {hasPreviousPage &&
          <li className="mx-2">
            <Link to={currentPage === 2 ? firstPath : `/blog/page/${currentPage - 1}`}>Prev</Link>
          </li>
        }
        {getPaginatonGroup().map(i =>
          <li key={i} className={`mx-2  rounded-full hover:bg-white hover:text-black dark:hover:bg-back dark:hover:bg-white transition-all duration-400 ${currentPage === i ? 'dark:bg-white dark:text-black bg-black text-white' : ''}`}>
            <Link className="flex justify-center items-center w-[30px] h-[30px]" to={i === 1 ? firstPath : "/blog/page/" + i} disabled={currentPage === i}>{i}</Link>
          </li>
        )}
        {hasNextPage &&
          <li className="mx-2">
            <Link to={`/blog/page/${currentPage + 1}`}>Next</Link>
          </li>
        }
      </ul>
    </div>
  );
}